// TIEP Style which DOES NOT include color.
// All color related styles go to tiep-core.scss
// or if theme specific color override, then to the theme file

@import url('../styles/material-icons.scss');
// #region Card with DrawerSidenav
.card-with-side-drawer {
  mat-drawer {
    width: 0;
  }

  &.side-drawer-add-edit-mode {
    mat-drawer {
      width: 100%;
      position: relative;
    }

    .card-side-drawer-main-content {
      display: none;
    }
  }
}

// #endregion

// #region Datatable Customizations
.compact-datatable {
  &.ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell {
    padding: 0.3rem 1.2rem;
  }

  .mat-mdc-icon-button {
    height: 22px !important;
    width: 22px !important;
    line-height: 22px;
  }

  .mat-icon {
    font-size: 22px;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
  &.ngx-datatable .mat-slide-toggle-bar {
    height: 6px !important;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
  &.ngx-datatable .mat-slide-toggle-thumb {
    height: 13px !important;
    width: 14px !important;
  }
  &.ngx-datatable .mat-mdc-slide-toggle .mat-slide-toggle-ripple {
    width: 25px !important;
    height: 25px !important;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
  &.ngx-datatable .mat-slide-toggle-bar {
    width: 25px !important;
  }
  &.ngx-datatable .mat-mdc-slide-toggle .mat-slide-toggle-ripple {
    top: calc(50% - 15px) !important;
    left: calc(50% - 15px) !important;
    border-top-left-radius: 15px !important;
    border-top-right-radius: 15px !important;
    border-bottom-right-radius: 15px !important;
    border-bottom-left-radius: 15px !important;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
  &.ngx-datatable .mat-mdc-slide-toggle.mat-checked .mat-slide-toggle-thumb-container {
    transform: translate3d(12px, 0, 0) !important;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
  &.ngx-datatable .mat-slide-toggle-thumb-container {
    top: -4px !important;
  }
}

.datatable-footer-on-top {
  &.ngx-datatable .datatable-footer /* .datatable-pager  */ {
    position: absolute;
    top: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    right: 0;
  }

  &.ngx-datatable .datatable-header {
    position: absolute;
    top: 50px;
  }

  &.ngx-datatable .datatable-body {
    margin-top: 100px;
  }

  @media (max-width: 450px) {
    &.ngx-datatable.material .datatable-footer .page-count {
      line-height: 50px;
      height: 50px;
      padding: 0 0 0 0.2rem;
    }

    &.ngx-datatable.material .datatable-footer .datatable-pager {
      font-size: 15px;
      line-height: 15px;
      padding: 0px;
      margin: 0px;
      /* height: 22px; */
      min-width: 24px;
      /* line-height: 22px; */
      /* padding: 0 6px; */
      /* border-radius: 3px; */
      /* margin: 6px 3px; */
      /*  text-align: center; */
      vertical-align: middle;
      color: rgba(0, 0, 0, 0.54);
      text-decoration: none;
    }
    &.ngx-datatable.material .datatable-footer .datatable-pager .datatable-icon-prev {
      font-size: 15px;
      line-height: 15px;
      padding: 0px;
    }
    &.ngx-datatable.material .datatable-footer .datatable-pager .datatable-icon-left {
      font-size: 15px;
      line-height: 15px;
      padding: 0px;
    }
    &.ngx-datatable.material .datatable-footer .datatable-pager .datatable-icon-right {
      font-size: 15px;
      line-height: 15px;
      padding: 0px;
    }
    &.ngx-datatable.material .datatable-footer .datatable-pager .datatable-icon-skip {
      font-size: 15px;
      line-height: 15px;
      padding: 0px;
    }
    &.datatable-footer-on-top {
      &.ngx-datatable .datatable-footer {
        position: absolute;
        top: 0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
        right: 0;
      }
    }
  }
}
//#endregion

// #region Compact mat-tree
.compact-mat-tree {
  .mat-icon {
    vertical-align: sub;
  }

  .material-icons {
    font-size: 21px;
  }

  .mat-tree {
    padding-top: 5px;
  }

  .mat-tree-node {
    height: 1.6em;
    font-size: 0.9em;
    min-height: 0;
    padding-right: 1em;
    margin-top: 0.2em;
    user-select: none;

    span {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}
// #endregion

// #region mat-grid
.mat-grid-tile .mat-grid-tile-content {
  justify-content: flex-start;
  align-items: flex-start;
}
// #endregion

// #region Dashboard Widget app-widget
app-widget {
  width: 100%;
  height: 100%;
}
// #endregion
.width-90-pc {
  width: 90% !important;
}
.wbreak {
  white-space: normal !important;
  word-wrap: break-word;
}
